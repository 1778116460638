import * as React from 'react';
import ModelLoader from './modelLoader';
import { emailData } from './modelLoader';
import names from "../assets/names.json";
import { BooleanKeyframeTrack } from 'three';
import { useProgress } from '@react-three/drei';

export type designerState = {
    shape: string,
    size: string,
    band: string,
    pave1: string,
    paveMilgrain: string,
    paveEngraving: string,
    pave2: string,
    shankSplit: string,
    channelStyle: string,
    milgrain: string,
    engraving: string,
    basket: string,
    bezel: string,
    halo: string,
    haloMilgrain: string,
    prongStyle: string,
    sideStones: string,
    sideStonesBand: string,
    sideStonesBasket: string,
    shoulder: string,
    colorSelected: string,
    orientationNorth: boolean,
    price: number,
    reset: boolean
};

type optionsVisible = {
    prong6: boolean,
    compass: boolean,
    split: boolean,
    HalfMoon: boolean,
    HalfMoon_Tapered: boolean,
    StepCut_Trapezoid: boolean,
    StepCut_Trapezoid2: boolean,
    Tapered_Bag: boolean,
    Pear: boolean,
    Trapezoid_Brilliant: boolean,
    Trapezoid_Brilliant2: boolean,
    Bezel: boolean,
    Floating: boolean,
    Classic: boolean,
    UShape: boolean
}

export class Main extends React.Component<{}, any> {
    private _modelLoader: any;
    private nextStep: any;
    private modalOpen: boolean;
    private emailTo: any;
    private emailSubject: any;
    private emailBody: any;
    private imageURL: string;
    private optionsVisible: optionsVisible;
    url: string | null;
    urlParams: URLSearchParams | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            shape: 'Kwiat Round™',
            size: 1,
            band: "",
            pave1: "",
            paveMilgrain: "",
            paveEngraving: "",
            pave2: "",
            shankSplit: "",
            channelStyle: "",
            milgrain: "",
            engraving: "",
            basket: "",
            bezel: "",
            halo: "",
            haloMilgrain: "",
            prongStyle: "",
            sideStones: "",
            sideStonesBand: "",
            sideStonesBasket: "",
            shoulder: "",
            colorSelected: 'Platinum',
            orientationNorth: true,
            loader: false,
            price: 0,
            emailTo: '',
            emailCc: '',
            emailBcc: '',
            emailSubject: '',
            emailBody: '',
            emailError: false,
            reset: false,
            notification: false,
            smallloader: false
        };
        this.nextStep = "diamond";
        this.modalOpen = false;
        this.emailTo = "";
        this.emailBody = "";
        this.emailSubject = "";
        this.imageURL = "https://cdn7.kwiat.com/kwiat/3d-designer/search-icons/";
        this.optionsVisible = {
            prong6: true,
            compass: true,
            split: true,
            HalfMoon: false,
            HalfMoon_Tapered: false,
            StepCut_Trapezoid: false,
            StepCut_Trapezoid2: false,
            Tapered_Bag: false,
            Pear: false,
            Trapezoid_Brilliant: false,
            Trapezoid_Brilliant2: false,
            Bezel: true,
            Floating: true,
            Classic: true,
            UShape: true
        };
        this.url = "";
    }

    componentDidMount(): void {
        setTimeout(() => {
            let urlParams = new URL(window.location.toLocaleString()).searchParams;
            if (urlParams.getAll("associate_email").length > 0) {
                this.setState({ emailTo: urlParams.getAll("associate_email")[0] });
                this.emailTo.value = urlParams.getAll("associate_email")[0];
            }

            let decodedURL = decodeURIComponent(window.location.toLocaleString());
            this.urlParams = new URL(decodedURL).searchParams;

            this.url = localStorage.getItem('url');
            if (this.url) {
                this.urlParams = new URL(location.protocol + '//' + location.host + location.pathname + this.url).searchParams;
                this.loadURLState();
            }
        }, 100);
    }

    loadURLState() {

        if (!this.urlParams) return;

        if (this.urlParams.getAll("price").length > 0) {
            this.setState({ price: parseInt(this.urlParams.getAll("price")[0]) });
        }

        if (this.urlParams.getAll("sketch").length > 0) {
            this.setState({ sketch: true });
        }

        if (this.urlParams.getAll("diamond").length > 0) {
            let diamondName = this.mapNameReverse(this.urlParams.getAll("diamond")[0]);
            // if (diamondName === "") diamondName = this.urlParams.getAll("diamond")[0];
            this.setState({ shape: diamondName, stone: diamondName });
        }
        if (this.urlParams.getAll("size").length > 0) {
            this.setState({ size: this.urlParams.getAll("size")[0] });
        }
        if (this.urlParams.getAll("band").length > 0) {
            this.setState({ band: this.urlParams.getAll("band")[0] });
        }
        if (this.urlParams.getAll("pave1").length > 0) {
            this.setState({ pave1: this.urlParams.getAll("pave1")[0] });
        }
        if (this.urlParams.getAll("paveMilgrain").length > 0) {
            this.setState({ paveMilgrain: this.urlParams.getAll("paveMilgrain")[0] });
        }
        if (this.urlParams.getAll("paveEngraving").length > 0) {
            this.setState({ paveEngraving: this.urlParams.getAll("paveEngraving")[0] });
        }
        if (this.urlParams.getAll("pave2").length > 0) {
            this.setState({ pave2: this.urlParams.getAll("pave2")[0] });
        }
        if (this.urlParams.getAll("shankSplit").length > 0) {
            this.setState({ shankSplit: this.urlParams.getAll("shankSplit")[0] });
        }
        if (this.urlParams.getAll("channelStyle").length > 0) {
            this.setState({ channelStyle: this.urlParams.getAll("channelStyle")[0] });
        }
        if (this.urlParams.getAll("basket").length > 0) {
            this.setState({ basket: this.urlParams.getAll("basket")[0] });
        }
        if (this.urlParams.getAll("bezel").length > 0) {
            this.setState({ bezel: this.urlParams.getAll("bezel")[0] });
        }
        if (this.urlParams.getAll("engraving").length > 0) {
            this.setState({ engraving: this.urlParams.getAll("engraving")[0] });
        }
        if (this.urlParams.getAll("halo").length > 0) {
            this.setState({ halo: this.urlParams.getAll("halo")[0] });
        }
        if (this.urlParams.getAll("haloMilgrain").length > 0) {
            this.setState({ haloMilgrain: this.urlParams.getAll("haloMilgrain")[0] });
        }
        if (this.urlParams.getAll("prongStyle").length > 0) {
            this.setState({ prongStyle: this.urlParams.getAll("prongStyle")[0] });
        }
        if (this.urlParams.getAll("sideStones").length > 0) {
            this.setState({ sideStones: this.urlParams.getAll("sideStones")[0] });
        }
        if (this.urlParams.getAll("sideStonesBand").length > 0) {
            this.setState({ sideStonesBand: this.urlParams.getAll("sideStonesBand")[0] });
        }
        if (this.urlParams.getAll("sideStonesBasket").length > 0) {
            this.setState({ sideStonesBasket: this.urlParams.getAll("sideStonesBasket")[0] });
        }
        if (this.urlParams.getAll("shoulder").length > 0) {
            this.setState({ shoulder: this.urlParams.getAll("shoulder")[0] });
        }
        if (this.urlParams.getAll("colorSelected").length > 0) {
            this.setState({ colorSelected: this.urlParams.getAll("colorSelected")[0] });
        }

        if (this.urlParams.getAll("associate_firstname").length > 0) {
            this.setState({ firstName: this.urlParams.getAll("associate_firstname")[0] });
        }

        if (this.urlParams.getAll("associate_lastname").length > 0) {
            this.setState({ lastName: this.urlParams.getAll("associate_lastname")[0] });
        }

        if (this.urlParams.getAll("associate_email").length > 0) {
            this.setState({ email: this.urlParams.getAll("associate_email")[0] });
        }

        if (this.urlParams.getAll("fullscreen").length > 0) {
            this.setState({ fullscreen: true });
        }
    }


    getPrice() {
        setTimeout(() => {
            this.setState({ price: this._modelLoader.getPrice() });
        }, 10);
    }

    changeDiamond() {

    }

    GetOrientation() {
        if (this.state.orientation)
            return "North-South";
        else
            return "East-West";
    }

    changeShank(number: number) {

        this._modelLoader.changeShank(number);
        if (number == 1) {
            this.setState({ colorSelected: "Platinum" })
        }
        else if (number == 2) {
            this.setState({ colorSelected: "Yellow Gold" })
        }
        else if (number == 3) {
            this.setState({ colorSelected: "Rose Gold" })
        }
        this.setState({ colorsTab: false });

        this.getPrice();
    }

    orientation(north: boolean) {
        this._modelLoader.changeOrientation(north);
        this.getPrice();
        this.setState({ orientation: !this.state.orientation });
    }

    tryon() {

        const callback = () => {
            setTimeout(() => {
                this.setState({ loader: false });
            }, 4000);
        };

        this._modelLoader.tryOn(callback);
        this.setState({ loader: true });
    }

    screenshot() {
        this._modelLoader.screenshot(false);
    }

    getOptionVisible(optionName: string): boolean {
        return false;
    }

    resetOptions() {
        this.optionsVisible = {
            prong6: true,
            compass: true,
            split: true,
            HalfMoon: false,
            HalfMoon_Tapered: false,
            StepCut_Trapezoid: false,
            StepCut_Trapezoid2: false,
            Tapered_Bag: false,
            Pear: false,
            Trapezoid_Brilliant: false,
            Trapezoid_Brilliant2: false,
            Bezel: true,
            Floating: true,
            Classic: true,
            UShape: true
        };
    }

    setOptionsVisible() {
        this.resetOptions();

        if (this.state.basket == "Plain" || this.state.basket == "Floating") {
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

            if (this.state.shape == "Kwiat Round™" || this.state.shape == "Fred Leighton Round™") {
                this.optionsVisible.prong6 = true;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = true;
            }
            else if (this.state.shape == "ASHOKA®") {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = true;
            }
            else {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = true;
                this.optionsVisible.split = true;
            }
        }

        else if (this.state.basket == "Classic") {
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

            if (this.state.shape == "Kwiat Round™" || this.state.shape == "Fred Leighton Round™") {
                this.optionsVisible.prong6 = true;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else if (this.state.shape == "ASHOKA®") {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = true;
                this.optionsVisible.split = false;
            }
        }

        if (this.state.pave2 == "Split") {
            this.optionsVisible.prong6 = false;
            this.optionsVisible.compass = false;
            this.optionsVisible.split = false;
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

            if (this.state.shape == "ASHOKA®") {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else if (this.state.shape == "Kwiat Round™" || this.state.shape == "Fred Leighton Round™") {
                this.optionsVisible.prong6 = true;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = true;
                this.optionsVisible.split = false;
            }
        }

        if (this.state.band == "Channel") {
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

            if (this.state.shape == "Kwiat Round™" || this.state.shape == "Fred Leighton Round™") {
                this.optionsVisible.prong6 = true;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else if (this.state.shape == "ASHOKA®" || this.state.shape == "Kwiat Emerald Cut™") {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = false;
                this.optionsVisible.split = false;
            }
            else {
                this.optionsVisible.prong6 = false;
                this.optionsVisible.compass = true;
                this.optionsVisible.split = false;
            }
        }

        if (this.state.halo == "Pave") {
            this.optionsVisible.prong6 = false;
            this.optionsVisible.compass = false;
            this.optionsVisible.split = false;
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

        }

        if (this.state.basket == "Bezel" || this.state.basket == "UShape") {
            this.optionsVisible.prong6 = false;
            this.optionsVisible.compass = false;
            this.optionsVisible.split = false;
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

        }

        if (this.state.basket == "Bezel" || this.state.basket == "UShape") {
            this.optionsVisible.prong6 = false;
            this.optionsVisible.compass = false;
            this.optionsVisible.split = false;
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;
        }

        if (this.state.band == "3Stone") {
            this.optionsVisible.Bezel = false;
            this.optionsVisible.Floating = false;
            this.optionsVisible.UShape = false;
            this.optionsVisible.Classic = false;
            this.optionsVisible.HalfMoon = false;
            this.optionsVisible.HalfMoon_Tapered = false;
            this.optionsVisible.StepCut_Trapezoid = false;
            this.optionsVisible.StepCut_Trapezoid2 = false;
            this.optionsVisible.Trapezoid_Brilliant = false;
            this.optionsVisible.Trapezoid_Brilliant2 = false;
            this.optionsVisible.Pear = false;
            this.optionsVisible.Tapered_Bag = false;

            const shape = this.mapName(this.state.shape);

            if (shape == "Round") {
                this.optionsVisible.Pear = true;
                this.optionsVisible.Tapered_Bag = true;
            }
            else if (shape == "Radiant") {
                this.optionsVisible.Trapezoid_Brilliant = true;
                this.optionsVisible.Trapezoid_Brilliant2 = true;
                this.optionsVisible.Tapered_Bag = true;
            }
            else if (shape == "Oval") {
                this.optionsVisible.HalfMoon = true;
                this.optionsVisible.Pear = true;
                this.optionsVisible.Tapered_Bag = true;
            }
            else if (shape == "Emerald") {
                this.optionsVisible.StepCut_Trapezoid = true;
                this.optionsVisible.StepCut_Trapezoid2 = true;
                this.optionsVisible.Tapered_Bag = true;
            }
            else if (shape == "Cushion") {
                this.optionsVisible.HalfMoon = true;
                this.optionsVisible.HalfMoon_Tapered = true;
            }
            else if (shape == "Ashoka") {

            }

        }
    }

    CreateSteps() {

        let stepsCount = 0;
        let stepName: string[] = [];
        let backName: string[] = [];

        if (this.state.shape != '') {
            stepName[stepsCount] = this.state.shape;
            backName[stepsCount] = "Diamond";
            stepsCount++;
        }

        if (this.state.band != '') {
            stepName[stepsCount] = this.state.band;
            backName[stepsCount] = "Band";
            stepsCount++;
        }

        if (this.state.pave1 != '') {
            stepName[stepsCount] = this.state.pave1;
            backName[stepsCount] = "Pave 1";
            stepsCount++;
        }

        if (this.state.paveMilgrain != '') {
            stepName[stepsCount] = this.state.paveMilgrain;
            backName[stepsCount] = "Pave Milgrain";
            stepsCount++;
        }

        if (this.state.paveEngraving != '') {
            stepName[stepsCount] = this.state.paveEngraving;
            backName[stepsCount] = "Pave Engraving";
            stepsCount++;
        }

        if (this.state.pave2 != '') {
            stepName[stepsCount] = this.state.pave2;
            backName[stepsCount] = "Pave 2";
            stepsCount++;
        }

        if (this.state.shankSplit != '') {
            stepName[stepsCount] = this.state.shankSplit;
            backName[stepsCount] = "Split Shank";
            stepsCount++;
        }

        if (this.state.channelStyle != '') {
            stepName[stepsCount] = this.state.channelStyle;
            backName[stepsCount] = "Channel Style";
            stepsCount++;
        }

        if (this.state.milgrain != '') {
            stepName[stepsCount] = this.state.milgrain;
            backName[stepsCount] = "Milgrain";
            stepsCount++;
        }

        if (this.state.engraving != '') {
            stepName[stepsCount] = this.state.engraving;
            backName[stepsCount] = "Engraving";
            stepsCount++;
        }

        if (this.state.basket != '') {
            stepName[stepsCount] = this.state.basket;
            backName[stepsCount] = "Basket";
            stepsCount++;
        }

        if (this.state.bezel != '') {
            stepName[stepsCount] = this.state.bezel;
            backName[stepsCount] = "Bezel";
            stepsCount++;
        }

        if (this.state.halo != '') {
            stepName[stepsCount] = this.state.halo;
            backName[stepsCount] = "Halo";
            stepsCount++;
        }

        if (this.state.haloMilgrain != '') {
            stepName[stepsCount] = this.state.haloMilgrain;
            backName[stepsCount] = "Halo Milgrain";
            stepsCount++;
        }

        if (this.state.prongStyle != '') {
            stepName[stepsCount] = this.state.prongStyle;
            backName[stepsCount] = "Prong Style";
            stepsCount++;
        }

        if (this.state.sideStones != '') {
            stepName[stepsCount] = this.state.sideStones;
            backName[stepsCount] = "Side Stones";
            stepsCount++;
        }

        if (this.state.sideStonesBand != '') {
            stepName[stepsCount] = this.state.sideStonesBand;
            backName[stepsCount] = "Side Stones Band";
            stepsCount++;
        }

        if (this.state.sideStonesBasket != '') {
            stepName[stepsCount] = this.state.sideStonesBasket;
            backName[stepsCount] = "Side Stones Basket";
            stepsCount++;
        }

        let html = [];
        for (let counter = 0; counter < stepsCount; counter++) {
            let name = stepName[counter];

            names.names.filter((item) => {
                if (item.name == stepName[counter])
                    name = item.displayName;
            });

            let heading = backName[counter];

            names.names.filter((item) => {
                if (item.name == backName[counter])
                    heading = item.displayName;
            });

            html.push(<div className='progressStep' key={new Date().getTime() + Math.random()} style={{ display: 'block' }} onClick={() => this.GoBack(backName[counter])}>
                <div className='progressStepName'><span>{heading}</span> <span>{name}</span></div>
            </div>);
        }

        return (
            <div className='progressbar'>
                {html}
            </div >
        );
    }

    createHeading() {

        return null;

        let shape, band, pave1, paveMilgrain, paveEngraving, pave2, shankSplit, channelStyle, milgrain, engraving, basket, bezel, halo, haloMilgrain, prongStyle, sideStones, sideStonesBand, sideStonesBasket;

        if (this.state.shape != '')
            shape = <span className='selected'>You selected: {this.state.shape} Diamond </span>

        if (this.state.band != '')
            band = <span className='selected'>You selected: {this.state.band} Band </span>

        if (this.state.pave1 != '')
            pave1 = <span className='selected'>You selected: {this.state.pave1} Pave Style </span>

        if (this.state.paveMilgrain != '')
            paveMilgrain = <span className='selected'>You selected: {this.state.paveMilgrain} to PaveMilgrain </span>

        if (this.state.paveEngraving != '')
            paveEngraving = <span className='selected'>You selected: {this.state.shape} to Pave Engraving </span>

        if (this.state.pave2 != '')
            pave2 = <span className='selected'>You selected: {this.state.pave2} Pave Option </span>

        if (this.state.shankSplit != '')
            shankSplit = <span className='selected'>You selected: {this.state.shankSplit} Shank split </span>

        if (this.state.channelStyle != '')
            channelStyle = <span className='selected'>You selected: {this.state.channelStyle} as Channel Style </span>

        if (this.state.milgrain != '')
            milgrain = <span className='selected'>You selected: {this.state.milgrain} to Milgrains </span>

        if (this.state.engraving != '')
            engraving = <span className='selected'>You selected: {this.state.engraving} to Engraving </span>

        if (this.state.basket != '')
            basket = <span className='selected'>You selected: {this.state.basket} Basket Style </span>

        if (this.state.bezel != '')
            bezel = <span className='selected'>You selected: {this.state.bezel} Bezel </span>

        if (this.state.halo != '')
            halo = <span className='selected'>You selected: {this.state.halo} Halo Style </span>

        if (this.state.haloMilgrain != '')
            haloMilgrain = <span className='selected'>You selected: {this.state.haloMilgrain} to Halo Milgrains </span>

        if (this.state.prongStyle != '')
            prongStyle = <span className='selected'>You selected: {this.state.prongStyle} Prong Style </span>

        if (this.state.sideStones != '')
            sideStones = <span className='selected'>You selected: {this.state.sideStones} Side Stones </span>

        if (this.state.sideStonesBand != '')
            sideStonesBand = <span className='selected'>You selected: {this.state.sideStonesBand} Band Style </span>

        if (this.state.sideStonesBasket != '')
            sideStonesBasket = <span className='selected'>You selected: {this.state.sideStonesBasket} Basket Style </span>

        return (
            <div>
                {shape}
                {band}
                {pave1}
                {paveMilgrain}
                {paveEngraving}
                {pave2}
                {shankSplit}
                {channelStyle}
                {milgrain}
                {engraving}
                {basket}
                {bezel}
                {halo}
                {haloMilgrain}
                {prongStyle}
                {sideStones}
                {sideStonesBand}
                {sideStonesBasket}
            </div>
        )
    }

    GetNextStep() {
        let nextStep;

        if (this.nextStep == "diamond")
            nextStep = <this.diamonds scope={this} />
        else if (this.nextStep == "band")
            nextStep = <this.baseBand scope={this} />
        else if (this.nextStep == "pave1")
            nextStep = <this.pave1 scope={this} />
        else if (this.nextStep == "paveMilgrain")
            nextStep = <this.paveMilgrain scope={this} />
        else if (this.nextStep == "paveEngraving")
            nextStep = <this.paveEngraving scope={this} />
        else if (this.nextStep == "pave2")
            nextStep = <this.pave2 scope={this} />
        else if (this.nextStep == "shankSplit")
            nextStep = <this.shankSplit scope={this} />
        else if (this.nextStep == "channelStyle")
            nextStep = <this.channeStyle scope={this} />
        else if (this.nextStep == "milgrain")
            nextStep = <this.milgrain scope={this} />
        else if (this.nextStep == "engraving")
            nextStep = <this.engraving scope={this} />
        else if (this.nextStep == "basket")
            nextStep = <this.basket scope={this} />
        else if (this.nextStep == "bezel")
            nextStep = <this.bezel scope={this} />
        else if (this.nextStep == "halo")
            nextStep = <this.halo scope={this} />
        else if (this.nextStep == "haloMilgrain")
            nextStep = <this.haloMilgrain scope={this} />
        else if (this.nextStep == "prongStyle")
            nextStep = <this.prongStyle scope={this} />
        else if (this.nextStep == "sideStones")
            nextStep = <this.sideStones scope={this} />
        else if (this.nextStep == "sideStonesBand")
            nextStep = <this.sideStonesBand scope={this} />
        else if (this.nextStep == "sideStonesBasket")
            nextStep = <this.sideStonesBasket scope={this} />

        return (
            <div>
                {nextStep}
            </div>
        )
    }

    GoBack(step: string) {

        if (step == "reset") {
            this.nextStep = "diamond"
            this.setState({
                shape: "Kwiat Round™",
                band: "",
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
                reset: true,
                colorSelected: "Platinum"
            })
        }

        if (step == "Diamond") {
            this.nextStep = "diamond"
            this.setState({
                band: "",
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Band") {
            this.nextStep = "band"
            this.setState({
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Pave 1") {
            this.nextStep = "pave1"
            this.setState({
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Pave Milgrain") {
            this.nextStep = "paveMilgrain"
            this.setState({
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Pave Engraving") {
            this.nextStep = "paveEngraving"
            this.setState({
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Pave 2") {
            this.nextStep = "pave2"
            this.setState({
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Shank Split") {
            this.nextStep = "shankSplit"
            this.setState({
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Channel Style") {
            this.nextStep = "channelStyle"
            this.setState({
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Milgrain") {
            this.nextStep = "milgrain"
            this.setState({
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Engraving") {
            this.nextStep = "engraving"
            this.setState({
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Basket") {
            this.nextStep = "basket"
            this.setState({
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Bezel") {
            this.nextStep = "bezel"
            this.setState({
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Halo") {
            this.nextStep = "halo"
            this.setState({
                haloMilgrain: "",
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Halo Milgrain") {
            this.nextStep = "haloMilgrain"
            this.setState({
                prongStyle: "",
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Prong Style") {
            this.nextStep = "prongStyle"
            this.setState({
                sideStones: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Side Stones") {
            this.nextStep = "sideStones"
            this.setState({
                band: "",
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStonesBand: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Side Stones Band") {
            this.nextStep = "sideStonesBand"
            this.setState({
                band: "",
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
                sideStonesBasket: "",
            })
        }

        if (step == "Side Stones Basket") {
            this.nextStep = "sideStonesBasket"
            this.setState({
                band: "",
                pave1: "",
                paveMilgrain: "",
                paveEngraving: "",
                pave2: "",
                shankSplit: "",
                channelStyle: "",
                milgrain: "",
                engraving: "",
                basket: "",
                bezel: "",
                halo: "",
                haloMilgrain: "",
                prongStyle: "",
            })
        }
        setTimeout(() => {
            this.sendState();
        }, 10);
    }

    sendState() {
        let currentState: designerState = {
            shape: this.state.shape,
            size: this.state.size,
            band: this.state.band,
            pave1: this.state.pave1,
            paveMilgrain: this.state.paveMilgrain,
            paveEngraving: this.state.paveEngraving,
            pave2: this.state.pave2,
            shankSplit: this.state.shankSplit,
            channelStyle: this.state.channelStyle,
            milgrain: this.state.milgrain,
            engraving: this.state.engraving,
            basket: this.state.basket,
            bezel: this.state.bezel,
            halo: this.state.halo,
            haloMilgrain: this.state.haloMilgrain,
            prongStyle: this.state.prongStyle,
            sideStones: "",
            sideStonesBand: "",
            sideStonesBasket: "",
            shoulder: this.state.shoulder,
            colorSelected: this.state.colorSelected,
            orientationNorth: this.state.orientationNorth,
            price: this.state.price,
            reset: this.state.reset
        };

        console.log(this.state);
        this._modelLoader.setCompleteState(currentState);
        this.setState({ reset: false });
    }

    showLoader() {
        //this.setState({ smallloader: true });
        // console.log("Showing loader");
        // this.showSmallLoader = <div id='SmallLoader'></div>;
    }

    hideLoader = () => {

    }

    changeModel(name: string) {
        this._modelLoader.changeModel(name);
        this.setState({ currentStep: 2 });
    }

    increaseSize() {
        this._modelLoader.increaseSize(this.setSize.bind(this));
        this.getPrice();
    }

    decreaseSize() {
        this._modelLoader.decreaseSize(this.setSize.bind(this));
        this.getPrice();
    }

    setSize(size: number) {
        this.setState({ size: size });
    }

    GetSize() {
        return this.state.size;
    }

    mapName(name: string) {
        if (name === "Kwiat Round™")
            return "Round";
        else if (name === "Kwiat Cushion™")
            return "Cushion";
        else if (name === "Kwiat Emerald Cut™")
            return "Emerald";
        else if (name === "Kwiat Radiant™")
            return "Radiant";
        else if (name === "Kwiat Oval™")
            return "Oval";
        else if (name === "ASHOKA®")
            return "Ashoka";
        else if (name === "Fred Leighton Round™")
            return "Fred";
        else
            return "";
    }

    mapNameReverse(name: string) {
        if (name === "Round")
            return "Kwiat Round™";
        else if (name === "Cushion")
            return "Kwiat Cushion™";
        else if (name === "Emerald")
            return "Kwiat Emerald Cut™";
        else if (name === "Radiant")
            return "Kwiat Radiant™";
        else if (name === "Oval")
            return "Kwiat Oval™";
        else if (name === "Ashoka")
            return "ASHOKA®";
        else if (name === "Fred")
            return "Fred Leighton Round™";
        else
            return "";
    }

    diamonds({ ...props }) {
        const scope = { ...props }.scope;
        return (
            <div className='Options'>
                <span className='heading'>Select your Shape</span>
                <div id='OptionsShapes'>
                    {/* <div onClick={() => scope.changeModel(1)}> <img src="" /> Option 1</div>
                    <div onClick={() => scope.changeModel(2)}> <img src="" /> Option 2</div> */}
                    <div onClick={() => scope.diamondSelected("Kwiat Round™")}> <img src={scope.imageURL + "stone_round.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_round.png";
                    }} /> Kwiat Round&#8482;</div>
                    <div onClick={() => scope.diamondSelected("Kwiat Cushion™")}> <img src={scope.imageURL + "stone_cushion.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_cushion.png";
                    }} /> Kwiat Cushion&#8482; </div>
                    <div onClick={() => scope.diamondSelected("Kwiat Radiant™")}> <img src={scope.imageURL + "stone_radiant.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_radiant.png";
                    }} /> Kwiat Radiant&#8482; </div>
                    <div onClick={() => scope.diamondSelected("Kwiat Oval™")}> <img src={scope.imageURL + "stone_oval.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_oval.png";
                    }} /> Kwiat Oval&#8482;</div>
                    <div onClick={() => scope.diamondSelected("Kwiat Emerald Cut™")}> <img src={scope.imageURL + "stone_emarald.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_emarald.png";
                    }} />Kwiat Emerald Cut&#8482;</div>
                    <div onClick={() => scope.diamondSelected("ASHOKA®")}> <img src={scope.imageURL + "stone_ashoka.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_ashoka.png";
                    }} />ASHOKA&#174;</div>
                    <div onClick={() => scope.diamondSelected("Fred Leighton Round™")}> <img src={scope.imageURL + "stone_fred.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "stone_fred.png";
                    }} />Fred Leighton Round&#8482;</div>
                    {/*<div onClick={() => scope.diamondSelected("Cushion_Classic")}> <img src={scope.imageURL + "stone_cushion.png"} />Cushion Classic</div>
                    <div onClick={() => scope.diamondSelected("Heart")}> <img src={scope.imageURL + "stone_heart.png"} />Heart</div>
                    <div onClick={() => scope.diamondSelected("Princess")}> <img src={scope.imageURL + "stone_princess.png"} />Princess</div>
                    <div onClick={() => scope.diamondSelected("Marquise")}> <img src={scope.imageURL + "stone_marquise.png"} />Marquise</div>
                    <div onClick={() => scope.diamondSelected("Pear")}> <img src={scope.imageURL + "stone_pear.png"} />Pear</div> */}
                </div>
            </div>
        );
    }

    diamondSelected(name: string) {
        this._modelLoader.changeModel(name);
        this.setState({ shape: name });
        this.nextStep = "band";
        this.getPrice();
        this.showLoader();
        this.setOptionsVisible();
    }

    baseBand({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const name = scope.imageURL + scope.mapName(scope.state.shape) + "_";

        let display = true;
        if (scope.mapName(scope.state.shape) == "Fred") display = false;

        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Band</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.baseSelected("Pave")}> <img src={name + "base_pave.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Pave</div>
                    <div onClick={() => scope.baseSelected("Solitaire")}> <img src={name + "base_solitaire.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Solitaire </div>
                    <div onClick={() => scope.baseSelected("Channel")}> <img src={name + "base_channel.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Channel </div>
                    <div style={{ display: display ? 'Block' : 'none' }} onClick={() => scope.baseSelected("3Stone")}> <img src={name + "base_3Stone.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> 3 Stone </div>
                    {/* <div onClick={() => scope.baseSelected("SideStones")}> <img src={scope.imageURL + "base_sideStones.png"} /> Side Stones</div> */}
                </div>
            </div>
        )
    }

    baseSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ band: name });
        if (name == "Pave")
            this.nextStep = "pave1";
        else if (name == "Solitaire")
            this.nextStep = "basket";
        else if (name == "Channel")
            this.nextStep = "channelStyle";
        else if (name == "SideStones")
            this.nextStep = "sideStones";
        else if (name == "3Stone")
            this.nextStep = "basket";

        this._modelLoader.changeBand(name);
        this.getPrice();
        this.showLoader();
    }

    channeStyle({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Channel Style</span>
                <div id='OptionsShapes'>
                    {/* <div onClick={() => scope.channelSelected("Carre")}> <img src={scope.imageURL + "channel_carre.png"} /> Carre</div>
                    <div onClick={() => scope.channelSelected("Vintage")}> <img src={scope.imageURL + "channel_vintage.png"} /> Vintage Style Pave</div>
                    <div onClick={() => scope.channelSelected("Mixed")}> <img src={scope.imageURL + "channel_mixed.png"} /> Mixed Step Cut</div>
                    <div onClick={() => scope.channelSelected("Uniform")}> <img src={scope.imageURL + "channel_uniform.png"} /> Uniform Round</div> */}
                    <div onClick={() => scope.channelSelected("Baguettes")}> <img src={namePrefix + "channel_baguettes.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Baguettes</div>
                </div>
            </div>
        )
    }

    channelSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ channelStyle: name });
        this.nextStep = "milgrain";
        this._modelLoader.changeChannel(name);
        this.getPrice();
        this.showLoader();
    }

    milgrain({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Milgrain</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.milgrainSelected("Yes")}> <img src={namePrefix + "milgrain_yes.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Yes</div>
                    <div onClick={() => scope.milgrainSelected("No")}> <img src={namePrefix + "milgrain_no.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> No </div>
                </div>
            </div>
        )
    }

    milgrainSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ milgrain: name });
        this.nextStep = "prongStyle";
        this._modelLoader.changeMilgrain(name);
        this.getPrice();
        this.showLoader();
    }

    engraving({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Engraving</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.engravingSelected("Yes")}> <img src={scope.imageURL + "engraving_yes.png"} /> Yes </div>
                    <div onClick={() => scope.engravingSelected("No")}> <img src={scope.imageURL + "engraving_no.png"} /> No </div>
                </div>
            </div>
        )
    }

    engravingSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ engraving: name });
        this.nextStep = "basket";
        this._modelLoader.changeEngraving(name);
        this.getPrice();
        this.showLoader();
    }

    pave1({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Pave Style</span>
                <div id='OptionsShapes'>
                    {/* <div onClick={() => scope.pave1Selected("3_Row")}> <img src={scope.imageURL + "pave_3Row.png"} /> 3 Row Micropave</div>
                    <div onClick={() => scope.pave1Selected("Vintage")}> <img src={scope.imageURL + "pave_vintage.png"} /> Vintage Style Pave </div> */}
                    <div onClick={() => scope.pave1Selected("Classic Single")}> <img src={namePrefix + "pave_classic.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Classic Single Row Cutdown Pave </div>
                </div>
            </div>
        )
    }

    pave1Selected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ pave1: name });
        if (name == "3 Row Micropave")
            this.nextStep = "basket";
        else if (name == "Vintage Style")
            this.nextStep = "paveMilgrain";
        else if (name == "Classic Single")
            this.nextStep = "pave2";
        this._modelLoader.changePave1(name);
        this.getPrice();
        this.showLoader();
    }


    paveMilgrain({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Pave Milgrain</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.paveMilgrainSelected("Yes")}> <img src={scope.imageURL + "pave_milgrain_yes.png"} /> Yes</div>
                    <div onClick={() => scope.paveMilgrainSelected("No")}> <img src={scope.imageURL + "pave_milgrain_no.png"} /> No </div>
                </div>
            </div>
        )
    }

    paveMilgrainSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ paveMilgrain: name });
        this.nextStep = "paveEngraving";
        this._modelLoader.changePaveMilgrain(name);
        this.getPrice();
        this.showLoader();
    }

    paveEngraving({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Pave Engraving</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.paveEngravingSelected("Yes")}> <img src={scope.imageURL + "pave_engraving_yes.png"} /> Yes </div>
                    <div onClick={() => scope.paveEngravingSelected("No")}> <img src={scope.imageURL + "pave_engraving_no.png"} /> No </div>
                </div>
            </div>
        )
    }

    paveEngravingSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ paveEngraving: name });
        this.nextStep = "pave2";
        this._modelLoader.changePaveEngraving(name);
        this.getPrice();
        this.showLoader();
    }

    pave2({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Pave Option</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.pave2Selected("Split")}> <img src={namePrefix + "pave_split.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Split</div>
                    {/* <div onClick={() => scope.pave2Selected("Graduated")}> <img src={scope.imageURL + "pave_graduated.png"} /> Graduated </div> */}
                    <div onClick={() => scope.pave2Selected("Uniform")}> <img src={namePrefix + "pave_unifrom.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Uniform </div>
                </div>
            </div>
        )
    }

    pave2Selected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ pave2: name });
        if (name == "Split")
            this.nextStep = "shankSplit";
        else
            this.nextStep = "basket";
        this._modelLoader.changePave2(name);
        this.getPrice();
        this.showLoader();
    }

    shankSplit({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Shank Split</span>
                <div id='OptionsShapes'>
                    {/* <div onClick={() => scope.shankSplitSelected("Subtle")}> <img src={scope.imageURL + "split_subtle.png"} /> Subtle Split</div>
                    <div onClick={() => scope.shankSplitSelected("Wide")}> <img src={scope.imageURL + "split_wide.png"} /> Wide Split </div> */}
                    <div onClick={() => scope.shankSplitSelected("Medium")}> <img src={namePrefix + "split_medium.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Medium Split </div>
                </div>
            </div>
        )
    }

    shankSplitSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ shankSplit: name });
        this.nextStep = "basket";
        this._modelLoader.changeShankSplit(name);
        this.getPrice();
        this.showLoader();
    }

    basket({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Basket Style</span>
                <div id='OptionsShapes'>
                    <div style={{ display: scope.optionsVisible.Bezel ? 'Block' : 'none' }} onClick={() => scope.basketSelected("Bezel")}> <img src={namePrefix + "basket_bezel.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Bezel </div>
                    <div style={{ display: scope.optionsVisible.Floating ? 'Block' : 'none' }} onClick={() => scope.basketSelected("Floating")}> <img src={namePrefix + "basket_floating.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Floating </div>
                    <div style={{ display: scope.optionsVisible.UShape ? 'Block' : 'none' }} onClick={() => scope.basketSelected("Classic")}> <img src={namePrefix + "basket_classic_shoulder.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Classic Prong </div>
                    {/* <div onClick={() => scope.basketSelected("Prong")}> <img src={scope.imageURL + "basket_classic_noShoulder.png"} /> Classic Prong without Shoulder </div> */}
                    <div style={{ display: scope.optionsVisible.Classic ? 'Block' : 'none' }} onClick={() => scope.basketSelected("UShape")}> <img src={namePrefix + "basket_uShape.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> U Shape </div>
                    {/* 3 Stone Options */}
                    {scope.optionsVisible.HalfMoon ? <div onClick={() => scope.basketSelected("HalfMoon")}> <img src={namePrefix + "basket_HalfMoon.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Half Moon </div> : null}
                    {scope.optionsVisible.HalfMoon_Tapered ? <div onClick={() => scope.basketSelected("HalfMoon_Tapered")}> <img src={namePrefix + "basket_HalfMoon_Tapered.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Half Moon Tapered </div> : null}
                    {scope.optionsVisible.StepCut_Trapezoid ? <div onClick={() => scope.basketSelected("StepCut_Trapezoid")}> <img src={namePrefix + "basket_StepCut_Trapezoid.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Step Cut Trapezoid </div> : null}
                    {scope.optionsVisible.StepCut_Trapezoid2 ? <div onClick={() => scope.basketSelected("StepCut_Trapezoid2")}> <img src={namePrefix + "basket_StepCut_Trapezoid2.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Step Cut Trapezoid 2</div> : null}
                    {scope.optionsVisible.Tapered_Bag ? <div onClick={() => scope.basketSelected("Tapered_Bag")}> <img src={namePrefix + "basket_Tapered_Bag.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Tapered Baguettes </div> : null}
                    {scope.optionsVisible.Pear ? <div onClick={() => scope.basketSelected("Pear")}> <img src={namePrefix + "basket_Pear.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Pear </div> : null}
                    {scope.optionsVisible.Trapezoid_Brilliant ? <div onClick={() => scope.basketSelected("Trapezoid_Brilliant")}> <img src={namePrefix + "basket_Trapezoid_Brilliant.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Trapezoid Brilliant </div> : null}
                    {scope.optionsVisible.Trapezoid_Brilliant2 ? <div onClick={() => scope.basketSelected("Trapezoid_Brilliant2")}> <img src={namePrefix + "basket_Trapezoid_Brilliant2.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Trapezoid Brilliant 2</div> : null}
                </div>
            </div>
        )
    }

    basketSelected(name: string) {
        this._modelLoader.changeBasket(name);
        this.setState({ basket: name });
        if (name == "Bezel")
            this.nextStep = "bezel";
        else if (name != "UShape" && name != "HalfMoon" && name != "HalfMoon_Tapered" && name != "StepCut_Trapezoid" && name != "StepCut_Trapezoid2" && name != "Tapered_Bag" && name != "Pear" && name != "Trapezoid_Brilliant" && name != "Trapezoid_Brilliant2")
            this.nextStep = "halo";

        this.getPrice();
        this.showLoader();
    }

    bezel({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Bezel type</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.bezelSelected("Polished")}> <img src={namePrefix + "bezel_polished.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Polished </div>
                    <div onClick={() => scope.bezelSelected("Milgrain")}> <img src={namePrefix + "bezel_milgrain.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Milgrain </div>
                </div>
            </div>
        )
    }

    bezelSelected(name: string) {
        this.setState({ bezel: name });
        this._modelLoader.changeBezel(name);
        this.getPrice();
        this.showLoader();
    }

    halo({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Halo type</span>
                <div id='OptionsShapes'>
                    {/* <div onClick={() => scope.haloSelected("Step")}> <img src={scope.imageURL + "halo_step.png"} /> Step Cut Halo </div> */}
                    {/* <div onClick={() => scope.haloSelected("Vintage")}> <img src={scope.imageURL + "halo_vintage.png"} /> Vintage Style Halo </div> */}
                    <div onClick={() => scope.haloSelected("Pave")}> <img src={namePrefix + "halo_pave.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Pave Round Diamond Halo </div>
                    <div onClick={() => scope.haloSelected("HiddenPave")}> <img src={namePrefix + "hidden_pave.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Hidden Pave Halo </div>
                    <div onClick={() => scope.haloSelected("No")}> <img src={namePrefix + "halo_no.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> No Halo </div>
                </div>
            </div>
        )
    }

    haloSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ halo: name });
        if (name == "Step Cut" || name == "Vintage Style")
            this.nextStep = "haloMilgrain";
        else if (name == "HiddenPave" || name == "No")
            this.nextStep = "prongStyle";

        this._modelLoader.changeHalo(name);
        this.getPrice();
        this.showLoader();
    }

    haloMilgrain({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Halo Milgrain type</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.haloMilgrainSelected("Yes")}> <img src={scope.imageURL + "halo_milgrain_yes.png"} /> Yes </div>
                    <div onClick={() => scope.haloMilgrainSelected("No")}> <img src={scope.imageURL + "halo_milgrain_no.png"} /> No </div>
                </div>
            </div>
        )
    }

    haloMilgrainSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ haloMilgrain: name });
        this._modelLoader.changeHaloMilgrain(name);
        this.getPrice();
        this.showLoader();
    }

    prongStyle({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        //Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
        const namePrefix = scope.imageURL + scope.mapName(scope.state.shape) + "_";
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Prong Style</span>
                <div id='OptionsShapes'>
                    <div style={{ display: scope.optionsVisible.prong6 ? 'Block' : 'none' }} onClick={() => scope.prongStyleSelected("6Prong")}> <img src={namePrefix + "prong_6.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> 6 </div>
                    <div style={{ display: scope.optionsVisible.compass ? 'Block' : 'none' }} onClick={() => scope.prongStyleSelected("Compass")}> <img src={namePrefix + "prong_compass.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Compass </div>
                    <div onClick={() => scope.prongStyleSelected("ClassicClaw")}> <img src={namePrefix + "prong_classic.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Classic Claw Prong </div>
                    <div style={{ display: scope.optionsVisible.split ? 'Block' : 'none' }} onClick={() => scope.prongStyleSelected("Split")}> <img src={namePrefix + "prong_split.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = scope.imageURL + "fallback.png";
                    }} /> Split Claw Prong </div>
                </div>
            </div>
        )
    }

    prongStyleSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ prongStyle: name });
        this._modelLoader.changeProng(name);
        this.getPrice();
        this.showLoader();
    }

    sideStones({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Side Stone Style</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.sideStonesSelected("Round")}> <img src={scope.imageURL + "side_round.png"} /> Round </div>
                    <div onClick={() => scope.sideStonesSelected("Baguettes")}> <img src={scope.imageURL + "side_baguettes.png"} /> Baguettes </div>
                    <div onClick={() => scope.sideStonesSelected("Bullets")}> <img src={scope.imageURL + "side_bullets.png"} /> Bullets</div>
                    <div onClick={() => scope.sideStonesSelected("Pears")}> <img src={scope.imageURL + "side_pears.png"} /> Pears </div>
                    <div onClick={() => scope.sideStonesSelected("HalfBullet")}> <img src={scope.imageURL + "side_half_moon_bullet.png"} /> Half Moon /Bullet </div>
                    <div onClick={() => scope.sideStonesSelected("FLRound")}> <img src={scope.imageURL + "side_FLRound.png"} /> FL Round </div>
                    <div onClick={() => scope.sideStonesSelected("Cushion")}> <img src={scope.imageURL + "side_cushion.png"} /> Cushion </div>
                    <div onClick={() => scope.sideStonesSelected("Epaulettes")}> <img src={scope.imageURL + "side_epaulettes.png"} /> Epaulettes </div>
                    <div onClick={() => scope.sideStonesSelected("Trapezoids")}> <img src={scope.imageURL + "side_trapezoids.png"} /> Trapezoids </div>
                    <div onClick={() => scope.sideStonesSelected("HalfMoon")}> <img src={scope.imageURL + "side_half_moon.png"} /> Half Moon </div>
                    <div onClick={() => scope.sideStonesSelected("Trap")}> <img src={scope.imageURL + "side_trap_bullet.png"} /> Trap / Bullet </div>
                    <div onClick={() => scope.sideStonesSelected("Asscher")}> <img src={scope.imageURL + "side_asscher.png"} /> Asscher </div>
                    <div onClick={() => scope.sideStonesSelected("Ashoka")}> <img src={scope.imageURL + "side_ashoka.png"} /> Ashoka </div>
                    <div onClick={() => scope.sideStonesSelected("Emerald")}> <img src={scope.imageURL + "side_emarald.png"} /> Emerald </div>
                    <div onClick={() => scope.sideStonesSelected("Shields")}> <img src={scope.imageURL + "side_shields.png"} /> Shields </div>
                    <div onClick={() => scope.sideStonesSelected("Oval")}> <img src={scope.imageURL + "side_oval.png"} /> Oval </div>
                    <div onClick={() => scope.sideStonesSelected("Hearts")}> <img src={scope.imageURL + "side_hearts.png"} /> Hearts </div>
                    <div onClick={() => scope.sideStonesSelected("Radiant")}> <img src={scope.imageURL + "side_radiant.png"} /> Radiant </div>
                    <div onClick={() => scope.sideStonesSelected("Princess")}> <img src={scope.imageURL + "side_princess.png"} /> Princess </div>
                </div>
            </div>
        )
    }

    sideStonesSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ sideStones: name });
        this.nextStep = "sideStonesBand";
        this._modelLoader.changeSidestone(name);
        this.getPrice();
        this.showLoader();
    }

    sideStonesBand({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Band</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.sideStonesBandSelected("Vintage")}> <img src={scope.imageURL + "side_band_vintage.png"} /> Vintage Style </div>
                    <div onClick={() => scope.sideStonesBandSelected("Classic")}> <img src={scope.imageURL + "side_band_classic.png"} /> Classic Plain Band </div>
                    <div onClick={() => scope.sideStonesBandSelected("SingleRow")}> <img src={scope.imageURL + "side_band_pave.png"} /> Single Row Pave </div>
                </div>
            </div>
        )
    }

    sideStonesBandSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ sideStonesBand: name });
        this.nextStep = "sideStonesBasket";
        this._modelLoader.changeSidestoneBand(name);
        this.getPrice();
        this.showLoader();
    }

    sideStonesBasket({ ...props }) {
        const scope = { ...props }.scope;
        const heading = scope.createHeading();
        return (
            <div className='Options'>
                {heading}
                <span className='heading'>Select Basket Style</span>
                <div id='OptionsShapes'>
                    <div onClick={() => scope.sideStonesBasketSelected("Classic")}> <img src={scope.imageURL + "side_basket_classic.png"} /> Classic Prongs With Shoulders </div>
                    <div onClick={() => scope.sideStonesBasketSelected("Pave")}> <img src={scope.imageURL + "side_basket_pave.png"} /> Pave Round Diamond Halo </div>
                    <div onClick={() => scope.sideStonesBasketSelected("Bezel")}> <img src={scope.imageURL + "side_basket_bezel.png"} /> Bezel </div>
                    <div onClick={() => scope.sideStonesBasketSelected("Vintage")}> <img src={scope.imageURL + "side_basket_vintage.png"} /> Vintage Stye </div>
                </div>
            </div>
        )
    }

    sideStonesBasketSelected(name: string) {
        //this._modelLoader.changeBase(name);
        this.setState({ sideStonesBasket: name });
        this._modelLoader.changeSidestoneBasket(name);
        this.getPrice();
    }

    openColorsTab() {
        this.setState({ colorsTab: true });
    }

    GetColor() {
        const colorSelected = this.state.colorSelected;
        let showPlatinum = false;
        let showYellow = false;
        let showRose = false;

        if (colorSelected == "Platinum")
            showPlatinum = true;

        if (colorSelected == "Yellow Gold")
            showYellow = true;

        if (colorSelected == "Rose Gold")
            showRose = true;

        return (
            <div>
                <div onClick={() => this.openColorsTab()} style={{ display: (showPlatinum ? "block" : "none") }}> Platinum <span className='triangle_down1'></span></div>
                <div onClick={() => this.openColorsTab()} style={{ display: (showYellow ? "block" : "none") }}>  Yellow Gold <span className='triangle_down1'></span></div>
                <div onClick={() => this.openColorsTab()} style={{ display: (showRose ? "block" : "none") }}>  Rose Gold <span className='triangle_down1'></span></div>
            </div>
        )
    }

    loader() {

        const { progress, active } = useProgress();
        return (<div>
            {active && <div id='SmallLoader'></div>}
        </div>);

    }

    render() {
        //console.log(this.state);
        const nextStep = this.GetNextStep();
        const showing = this.state.colorsTab;
        const steps = this.CreateSteps();
        const selectedColor = this.GetColor();
        const size = this.GetSize();
        const orientation = this.GetOrientation();
        let price = this.state.price;

        this.setOptionsVisible();

        let decodedURL = decodeURIComponent(window.location.toLocaleString());
        let urlParams = new URL(decodedURL).searchParams;

        let displayFullscreen = false;
        if (urlParams.getAll("fullscreen").length > 0) {
            displayFullscreen = true;
        }


        let closeModal = () => {
            this.modalOpen = false;
            this.setState({ 'price': this.state.price });
        }

        let openModal = () => {
            this.modalOpen = true;
            this.emailSubject.value = "Your " + this.state.shape + " Diamond Dream Ring Design";
            this.emailBody.value = "Hi,\r\n \r\nBelow is a link to your Dream Ring Design where you can view and virtually try-on your ring.\r\n\r\nWarmly, \r\n\r\n" + associate_firstname + " " + associate_lastname;
            this.setState({ emailSubject: "Your " + this.state.shape + " Diamond Dream Ring Design", emailBody: "Hi,\r\n \r\nBelow is a link to your Dream Ring Design.\r\n\r\nWarmly, \r\n\r\n" + associate_firstname + " " + associate_lastname });
        }

        let associate_firstname = '';
        let associate_lastname = '';
        let associate_email = '';

        if (urlParams.getAll("associate_firstname").length > 0) {
            associate_firstname = urlParams.getAll("associate_firstname")[0];
        }

        if (urlParams.getAll("associate_lastname").length > 0) {
            associate_lastname = urlParams.getAll("associate_lastname")[0];
        }

        if (urlParams.getAll("associate_email").length > 0) {
            associate_email = urlParams.getAll("associate_email")[0];
        }

        let submitEmail = () => {

            if (/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(this.state.emailTo) == false) {
                this.setState({ emailError: true });
                return;
            }
            else {
                this.setState({ emailError: false });
            }

            const emailDataObject: emailData = {
                to: this.state.emailTo,
                bcc: this.state.emailBcc,
                cc: this.state.emailCc,
                subject: this.state.emailSubject,
                body: this.state.emailBody,
                associate_firstname: associate_firstname,
                associate_lastname: associate_lastname,
                associate_email: associate_email
            };

            this.setState({ loader: true });
            let callback = () => {
                this.setState({ loader: false });
            }

            this._modelLoader.sendEmail(emailDataObject, callback);
            this.modalOpen = false;
            this.emailTo.value = associate_email;
        }

        let copyLink = () => {
            this._modelLoader.copyLink();
            this.setState({ notification: true });
            setTimeout(() => {
                this.setState({ notification: false });
            }, 5000);
        }

        let reset = () => {
            this.GoBack('reset');
        }

        let fullscreenDesigner = displayFullscreen ? <ModelLoader ref={(ref: any) => (this._modelLoader = ref)} /> : <></>;
        let smallDesigner = !displayFullscreen ? <ModelLoader ref={(ref: any) => (this._modelLoader = ref)} /> : <></>;

        return (
            <>
                <div id="loader" style={{ display: this.state.loader ? 'block' : 'none' }}></div>
                <div id="notification" className={this.state.notification ? 'fading' : ''}> Link Copied</div >
                <div id="modalBackground" style={{ display: this.modalOpen ? 'block' : 'none' }}>
                    <div id="modalWindow">
                        <div id="modalHeading">
                            Share Design
                        </div>
                        <div id="modalContent">
                            <div id="labels">

                                <li className='error' style={{ display: this.state.emailError ? 'block' : 'none' }}></li>
                                <li> To * </li>
                                <li className='error'></li>
                                <li> Cc </li>
                                <li className='error'></li>
                                <li> Bcc </li>
                                <li className='error'></li>
                                <li> Subject </li>
                                <li className='error'></li>
                                <li> Message </li>

                            </div>
                            <div id="formFields">

                                <li className='error' style={{ display: this.state.emailError ? 'block' : 'none' }}> Error! Enter a valid email address!</li>
                                <li> <input type='text' id="formFieldTo" ref={(ref: any) => (this.emailTo = ref)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ emailTo: event.target.value }); }} /></li>
                                <li className='error'></li>
                                <li> <input type='text' id="formFieldCC" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ emailCc: event.target.value }) }} /></li>
                                <li className='error'></li>
                                <li> <input type='text' id="formFieldBCC" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ emailBcc: event.target.value }) }} /></li>
                                <li className='error'></li>
                                <li> <input type='text' id="formFieldSubject" ref={(ref: any) => (this.emailSubject = ref)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ emailSubject: event.target.value }) }} /></li>
                                <li className='error'></li>
                                <li> <textarea id="formFieldSubject" ref={(ref: any) => (this.emailBody = ref)} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { this.setState({ emailBody: event.target.value }) }} /></li>

                            </div>
                        </div>
                        <div id="modalFooter">
                            <div id="cancelButton" onClick={closeModal}> Cancel </div>
                            <div id="submitButton" onClick={submitEmail}> Send </div>
                        </div>
                        <div style={{ marginTop: -30, marginLeft: 220 }}> Fields marked with * are required. Separate multiple email addresses with a comma </div>

                    </div>
                </div >
                <this.loader ></this.loader>
                <div style={{
                    display: "block",
                    position: "fixed",
                }} className={displayFullscreen ? 'hidden' : 'visible'} >
                    <div id="head">
                        <span className='mainHeading'>Design Your Dream Ring</span>
                        {steps}
                    </div>

                    <div id="Main">

                        <div id='three'>
                            {smallDesigner}
                        </div>

                        <div id='OptionsContainer'>
                            {nextStep}
                        </div>
                        <div className='rightButtonsBottom' style={{ display: (!showing ? 'block' : 'none') }}>
                            <span className='heading link'> {selectedColor} </span>
                        </div>

                        <div className='rightButtonsBottomExpanded' style={{ display: (showing ? 'block' : 'none') }}>
                            <div id='OptionsColor'>
                                <div onClick={() => this.changeShank(1)}> <span className='circle platinum'></span> Platinum</div>
                                <div onClick={() => this.changeShank(2)}> <span className='circle gold'></span>18K Yellow Gold</div>
                                <div onClick={() => this.changeShank(3)}> <span className='circle roseGold'></span>18K Rose Gold</div>
                            </div>
                        </div>

                        <div className='rightButtonsTop' onClick={() => this.tryon()} >
                            <span className='heading' > </span>
                        </div>

                        <div className='leftButtonsBottom' onClick={openModal}>

                        </div>
                        <div className='leftButtonsBottom2' onClick={copyLink}>

                        </div>
                        <div className='TopButton2' onClick={reset}>
                            RESET ALL
                        </div>
                        <div className='Disclaimer'>
                            Disclaimer: These designs are directionally representative, but not necessarily exactly how your ring will be created as each ring is unique to it's center diamond, made just for you. The center diamond in this design is ~3ct.
                        </div>
                    </div>
                </div>
                <div className={displayFullscreen ? 'visible' : 'hidden'}>
                    <div id="head">
                        <span className='mainHeading'>Design Your Dream Ring</span>
                    </div>
                    <div id="Main">
                        <div id='threeFullScreen'>
                            {fullscreenDesigner}
                        </div>
                        <div className='rightButtonsTopFullScreen' onClick={() => this.tryon()} >
                            <span className='heading' > </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;